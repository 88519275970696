import React from "react";
import { useMedflickApi } from "../../context/medflickContext";

const ConvertedLeadList = ({ convertedLead }) => {
  const { getBranchNameById } = useMedflickApi();
  return (
    <>
      <div className="table-container">
        <table className="details-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Branch Name</th>
              <th>Cost</th>
              <th>Treatment Type</th>
            </tr>
          </thead>
          <tbody>
            {convertedLead?.map((e) => (
              <tr key={e?.id}>
                <td>{e.id}</td>
                <td>{getBranchNameById(e?.hospital_id)}</td>
                <td>&#8377; {e?.cost}</td>
                <td>{e?.leadType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ConvertedLeadList;

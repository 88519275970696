import React from "react";
import Filter from "../HomePage/components/Filter";
import "./leadsearch.css";
import { useGetLeadApi } from "../context/getAllLeadContext";

const LeadSearch = () => {
  const { query, setQuery } = useGetLeadApi();

  return (
    <>
      <div className="leads-crm-search-box">
        <input
          type="text"
          placeholder="Search ..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      {/* <Filter /> */}
    </>
  );
};

export default LeadSearch;

import React, { createContext, useState, useContext, useEffect } from "react";
import {
  axiosMedflickInstance,
  axiosInstance,
} from "../axiosInstance/axiosInstance";
import axios from "axios";

const GetMedflickDataContext = createContext();

export const MedflickProvider = ({ children }) => {
  const [hospitals, setHospitals] = useState([]);
  const [branches, setBranches] = useState([]);
  const [treatmentUploadList, setTreatmentUploadList] = useState([]);

  const getAllUploadedTreatment = async () => {
    try {
      const response = await axiosInstance.get("/SpecialityList");
      setTreatmentUploadList(response.data.specialityList);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllBranch = async () => {
    try {
      const response = await axios.get(
        "https://webnetramapi.elihealthsupport.com:2034/Api/GetBranches/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IndlcnR2Y2FmZ2ZmZ2VyZXI0Z2ZnIiwibmJmIjoxNzI2ODkzODM5LCJleHAiOjE3MjY5ODAyMzksImlhdCI6MTcyNjg5MzgzOX0.RKMxeuVvQHNfdaCxHPpLTGUkx_Cr5mHQR3-MprsIzvw"
      );
      setBranches(response.data);

      return response.data;
    } catch (err) {
      console.log(err.message);
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    getAllBranch();
  }, []);

  const getAllHospitals = async () => {
    try {
      const response = await axiosMedflickInstance.get("/search");
      setHospitals(response.data.searchData.hospitals);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getBranchNameById = (hospitalId) => {
    const hospital = branches.find(
      (h) => h.PK_BranchId === parseInt(hospitalId)
    );
    return hospital ? hospital.BranchName : "Hospital Not Found";
  };

  return (
    <GetMedflickDataContext.Provider
      value={{
        getAllHospitals,
        hospitals,
        getAllUploadedTreatment,
        treatmentUploadList,
        branches,
        getAllBranch,
        getBranchNameById,
      }}
    >
      {children}
    </GetMedflickDataContext.Provider>
  );
};

export const useMedflickApi = () => {
  return useContext(GetMedflickDataContext);
};

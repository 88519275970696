import React from "react";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsBrowserChrome } from "react-icons/bs";
import { encryptId } from "../../utils/cryptoUtils";
import { FaRocketchat } from "react-icons/fa";
import { IoPersonAdd } from "react-icons/io5";
import LeadType from "../../Common/LeadType";
const LeadList = ({ adminLeadList, teamData, userId }) => {
  const AssignName = teamData.find((e) => e?.id == userId);
  return (
    <>
      <div className="scrollbar">
        {adminLeadList?.map((lead, index) => {
          return (
            <div
              className="leads-patient-doc-crm"
              key={index}
              style={{
                background: lead?.status === "Close Lead" ? "#A9A9A9" : "",
              }}
              title={lead?.status === "Close Lead" ? "This lead is closed" : ""}
            >
              <div className="leads-patient-name">{lead?.patient_name}</div>
              <div className="leads-patient-country">
                <img src="images/2024/01/02/flag-india.png" alt="India" /> India
              </div>
              <div className="leads-patient-source">
                {lead?.platform === "Whatsapp" ? (
                  <i>
                    <FaWhatsapp className="fa-whatsapp" fontSize={25} />
                  </i>
                ) : lead?.platform === "Tawk_TO" ? (
                  <i>
                    <FaRocketchat className="fa-facebook-f" fontSize={23} />
                  </i>
                ) : lead?.platform === "Facebook" ? (
                  <i>
                    <FaFacebookF className="fa-facebook-f" />
                  </i>
                ) : lead?.platform === "Landing Page" ||
                  lead?.platform === "Website Query" ? (
                  <i>
                    <BsBrowserChrome className="fab fa-website" fontSize={23} />
                  </i>
                ) : (
                  <i>
                    <IoPersonAdd
                      className="fa-facebook-f"
                      style={{ background: "#737373" }}
                    />
                  </i>
                )}
              </div>
              <div class="leads-patient-speciality">
                <LeadType leadId={lead?.id} leadtype={lead?.lead_type} />
              </div>
              <div className="leads-patient-report-status">Not Uploaded</div>
              <div className="leads-patient-referred" style={{ color: "#000" }}>
                {AssignName?.name}
              </div>
              <div className="leads-patient-status">Stage 1/4</div>
              {lead?.user_id && (
                <div className="leads-patient-view">
                  <Link
                    to={`/patient/${encryptId(lead?.id)}`}
                    data-popup-open="popup-7"
                    className="view"
                  >
                    View
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LeadList;

import React, { useEffect, useState, useRef } from "react";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { Link } from "react-router-dom";
import { useWhatsappApi } from "../../context/whatsappContex";
import { BsBrowserChrome } from "react-icons/bs";
import { encryptId } from "../../utils/cryptoUtils";
import { FaRocketchat, FaEye } from "react-icons/fa";
import { IoPersonAdd } from "react-icons/io5";
import LeadType from "../../Common/LeadType";
import { IoIosCall } from "react-icons/io";

const formatDate = (isoString) => {
  const date = new Date(isoString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${day}${getDaySuffix(
    day
  )} ${month} ${year} ${formattedHours}:${formattedMinutes} ${period}`;
};

const getDaySuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const LeadList = ({
  toggleEdit,
  showEdit,
  startDate,
  endDate,
  platformSelect,
  selectedTreatment,
  leadStatus,
  setSelectedSource,
  selectedSource,
}) => {
  const {
    allLead,
    getAllLead,
    loading,
    totalpage,
    setPage,
    page,
    displayData,
    query,
  } = useGetLeadApi();

  const [leadLoading, setLeadLoading] = useState(false);
  const scrollRef = useRef();

  const { teamData, fetchTeamData, postUserIdLeadId } = useWhatsappApi();

  

  useEffect(() => {
    getAllLead(
      page,
      startDate,
      endDate,
      platformSelect,
      selectedTreatment,
      leadStatus,
      selectedSource
    );
    fetchTeamData();
  }, [
    page,
    endDate,
    startDate,
    platformSelect,
    selectedTreatment,
    leadStatus,
    selectedSource,
  ]);

  const handleNextPage = () => {
    if (page < totalpage) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleScroll = () => {
    const scrollTop = scrollRef.current.scrollTop;
    const scrollHeight = scrollRef.current.scrollHeight;
    const clientHeight = scrollRef.current.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading]);

  const handleTeamChange = async (e, leadId) => {
    const userId = e.target.value;
    setLeadLoading(true);
    try {
      const result = await postUserIdLeadId(userId, leadId);
      setLeadLoading(false);
      getAllLead(page);
    } catch (error) {
      console.error("Error posting data:", error);
      setLeadLoading(false);
    }
  };

  return (
    <>
      {(loading || leadLoading) && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}

      <div className="scrollbar">
        {displayData?.map((lead, index) => {
          let patientName = lead?.patient_name;

          if (
            lead?.platform === "Website Query" ||
            lead?.platform === "Landing Page"
          ) {
            if (!patientName || patientName.trim() === "") {
              return null;
            }
          }

          if (lead?.platform === "Tawk_TO") {
            if (!patientName || patientName.trim() === "") {
              return null;
            }
            const nameOnly = patientName.split("\r\n")[0];
            patientName = nameOnly;
          } else {
            const parsedFormData = JSON.parse(lead?.form_data);
            const fullNameData = parsedFormData?.find(
              (field) => field.name === "full_name"
            );
            patientName = fullNameData?.values?.[0] || lead?.patient_name;
          }

          return (
            <div
              className="leads-patient-doc-crm"
              key={index}
              style={{
                background: lead?.status === "Close Lead" ? "#A9A9A9" : "",
              }}
              title={lead?.status === "Close Lead" ? "This lead is closed" : ""}
            >
              <div className="leads-patient-name" style={{ display: "block" }}>
                {patientName}{" "}
                <div style={{ color: "#3e3eff" }}>
                  {formatDate(lead?.created_at)}{" "}
                </div>
              </div>
              <div className="leads-patient-country">
                <img src="images/2024/01/02/flag-india.png" alt="India" /> India
              </div>
              <div className="leads-patient-source">
                {lead?.platform === "Whatsapp" ? (
                  <i>
                    <FaWhatsapp className="fa-whatsapp" fontSize={25} />
                  </i>
                ) : lead?.platform === "Tawk_TO" ? (
                  <i>
                    <FaRocketchat className="fa-facebook-f" fontSize={23} />
                  </i>
                ) : lead?.platform === "Knowlarity" ? (
                  <i>
                    <IoIosCall
                      className="fa-whatsapp"
                      fontSize={25}
                      style={{ background: "#000" }}
                    />
                  </i>
                ) : lead?.platform === "Facebook" ? (
                  <i>
                    <FaFacebookF className="fa-facebook-f" />
                  </i>
                ) : lead?.platform?.includes("Campaign") ||
                  lead?.platform?.includes("Landing Page") ||
                  lead?.platform === "Website Query" ? (
                  <i>
                    <BsBrowserChrome className="fab fa-website" fontSize={23} />
                  </i>
                ) : lead?.platform === "Netram" ? (
                  <i>
                    <FaEye className="fa-facebook-f" />
                  </i>
                ) : (
                  <i>
                    <IoPersonAdd
                      className="fa-facebook-f"
                      style={{ background: "#737373" }}
                    />
                  </i>
                )}
              </div>
              <div className="leads-patient-speciality">
                <LeadType leadId={lead?.id} leadtype={lead?.lead_type} />
              </div>
              {lead?.patient_id ? (
                <div
                  className="leads-patient-report-status"
                  style={{ cursor: "pointer", color: "green" }}
                  onClick={(e) => toggleEdit(lead?.patient_id)}
                >
                  Edit Patient
                </div>
              ) : (
                <div className="leads-patient-report-status">Assign First</div>
              )}

              <div className="leads-patient-referred">
                <select
                  onChange={(e) => handleTeamChange(e, lead?.id)}
                  value={lead?.user_id || " "}
                >
                  <option value=" ">Assign Lead</option>
                  {teamData.map((team) => (
                    <option key={team?.id} value={team?.id}>
                      {team?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="leads-patient-status">
                {lead?.status === "Close Lead" ? "Close Lead" : "Open Lead"}
              </div>
              {lead?.user_id && (
                <div className="leads-patient-view">
                  <Link
                    to={`/patient/${encryptId(lead?.id)}`}
                    data-popup-open="popup-7"
                    className="view"
                  >
                    View
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {!query && !startDate && !endDate && (
        <div>
          <button
            onClick={handlePrevPage}
            disabled={page <= 1}
            style={{ marginRight: "10px" }}
          >
            Prev Page
          </button>
          <button onClick={handleNextPage} disabled={page >= totalpage}>
            Next Page
          </button>
          <p>
            Page {page} of {totalpage}
          </p>
        </div>
      )}
    </>
  );
};

export default LeadList;

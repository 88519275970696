import React from "react";
import LeftSlider from "../Inc/LeftSlider";
import Graph from "../HomePage/components/Graph";
import Filter from "../HomePage/components/Filter";
import CallLead from "./CallLead";

const CallLogHome = () => {
  return (
    <>
      <div className="medflick-mod">
        <LeftSlider path="call-log" />
        <div className="medflick-mod-right">
          <div className="scrollbar-1">
            {/* <Graph /> */}

            <div className="leads-search-doc">
              <h2>Leads</h2>

              <div className="leads-crm-search">
                <div className="leads-crm-search-box">
                  <input
                    type="text"
                    placeholder="Search ..."
                    name="name"
                    required=""
                  />
                </div>
                <Filter />
              </div>
            </div>

            <div className="leads-patient-box">
              <div className="leads-patient-head">
                <div className="leads-patient-head-box1">Patient Number</div>
                <div className="leads-patient-head-box2">Country</div>
                <div className="leads-patient-head-box3">Source</div>
                <div className="leads-patient-head-box4">Lead Type</div>
                <div className="leads-patient-head-box5">Report Status</div>
                <div className="leads-patient-head-box6">Assign To</div>
                <div className="leads-patient-head-box7">Status</div>
                <div className="leads-patient-head-box8">Action</div>
              </div>

              {/* <LeadList toggleEdit={toggleEdit} showEdit={showEdit} /> */}
              <CallLead />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallLogHome;

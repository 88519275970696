import React from "react";
import Header from "../Inc/header/Header";
import Home from "../HomePage/components/Home";
import CallLogHome from "./CallLogHome";

const CallLog = () => {
  return (
    <>
      <section id="medflick-mod">
        {/* <Header />
        <Home /> */}
        <Header />
        <CallLogHome />
      </section>
    </>
  );
};

export default CallLog;

import React, { useEffect, useState } from "react";
import LeftSlider from "../../Inc/LeftSlider";
import Select from "react-select";
import { useMedflickApi } from "../../context/medflickContext";
import { useParams } from "react-router-dom";
import { decryptId } from "../../utils/cryptoUtils";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import "./form.css";
const UploadConveredLead = () => {
  const { pid, uid } = useParams();
  const { branches, getAllBranch } = useMedflickApi();
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [loading, setLoading] = useState(false);
  const [cost, setCost] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    getAllBranch();
  }, []);
  const handleBranchChange = (e) => {
    const { value } = e.target;
    setSelectedBranch(value);
  };

  const handleSubmit = () => {
    setLoading(true);

    if (!selectedBranch) {
      alert("Please Select Branch.");
      setLoading(false);
      return;
    }

    if (!cost) {
      alert("Please enter the cost.");
      setLoading(false);
      return;
    }
    if (!type) {
      alert("Please Select Treatment Type.");
      setLoading(false);
      return;
    }

    axiosInstance
      .post("/converted_patients", {
        hospital_id: selectedBranch,
        user_id: userId,
        patient_id: patinetId,
        cost: cost,
        leadType: type,
      })
      .then((response) => {
        alert("Data Upload Successfully");
        setSelectedBranch("");
        setCost("");
        setType("");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to send emails.");
        setLoading(false);
      });
  };

  return (
    <>
      <section id="medflick-mod">
        <div className="medflick-mod">
          <LeftSlider />
          <div className="medflick-mod-right">
            <div className="scrollbar-1">
              <div className="query-form-page">
                <div className="medflick-visa-right">
                  <h2 style={{ color: "" }}>Add Converted Lead</h2>
                  <div className="visa-form">
                    <div className="visa-form-box">
                      <label>Select Branch*</label>
                      <select
                        value={selectedBranch}
                        onChange={handleBranchChange}
                      >
                        <option>Select Option</option>
                        {branches?.map((e) => (
                          <option value={e?.PK_BranchId} key={e?.PK_BranchId}>
                            {e?.BranchName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="visa-form-box">
                      <label>Total Cost* (in Rupee)</label>
                      <input
                        type="number"
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Treatment Type*</label>
                      <select
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option value="">Select Type</option>
                        <option value="IPD">IPD</option>
                        <option value="OPD">OPD</option>
                        <option value="OTHER">OTHER</option>
                      </select>
                    </div>
                    <div className="visa-form-box">
                      <button
                        type="submit"
                        className="visa-submit"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Submit Now"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadConveredLead;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaRocketchat } from "react-icons/fa";
import { BsBrowserChrome } from "react-icons/bs";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { useWhatsappApi } from "../context/whatsappContex";
import LeadType from "../Common/LeadType";
import { axiosInstance } from "../axiosInstance/axiosInstance";
import { Link } from "react-router-dom";
import { encryptId } from "../utils/cryptoUtils";

const CallLead = () => {
  const [allCall, setAllCall] = useState([]);
  const [leadLoading, setLeadLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { teamData, fetchTeamData, postUserIdLeadId } = useWhatsappApi();
  useEffect(() => {
    fetchTeamData();
    setLoading(true);
    const fetchPages = async () => {
      try {
        const response = await axiosInstance.get(`/callLogList`);
        setAllCall(response.data?.data);
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPages();
  }, []);

  const handleTeamChange = async (e, leadId) => {
    const userId = e.target.value;
    setLeadLoading(true);
    try {
      const result = await postUserIdLeadId(userId, leadId);
      setLeadLoading(false);
    } catch (error) {
      console.error("Error posting data:", error);
      setLeadLoading(false);
    }
  };

  return (
    <>
      {(loading || leadLoading) && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      {allCall?.map((lead, index) => (
        <div className="leads-patient-doc-crm" key={index}>
          <div className="leads-patient-name"> {lead?.customer_number}</div>
          <div className="leads-patient-country">
            <img src="images/2024/01/02/flag-india.png" alt="India" /> India
          </div>
          <div className="leads-patient-source">
            <i>
              <IoIosCall
                className="fa-whatsapp"
                fontSize={25}
                style={{ background: "#000" }}
              />
            </i>
          </div>
          <div className="leads-patient-speciality">
            <LeadType leadId={lead?.id} leadtype={lead?.lead_type} />
          </div>
          {lead?.patient_id ? (
            <div
              className="leads-patient-report-status"
              style={{ cursor: "pointer", color: "green" }}
              //   onClick={(e) => toggleEdit(lead?.patient_id)}
            >
              Edit Patient
            </div>
          ) : (
            <div className="leads-patient-report-status">Assign First</div>
          )}
          <div className="leads-patient-referred">
            <select
              onChange={(e) => handleTeamChange(e, lead?.id)}
              value={lead?.user_id || " "}
            >
              <option value=" ">Assign Lead</option>
              {teamData.map((team) => (
                <option key={team?.id} value={team?.id}>
                  {team?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="leads-patient-status">Stage 1/4</div>

          <div className="leads-patient-view">
            <Link
              to={`/patient/${encryptId(lead?.id)}`}
              data-popup-open="popup-7"
              className="view"
            >
              View
            </Link>
          </div>
        </div>
      ))}
    </>
  );
};

export default CallLead;

import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://wavikarcrm.wavikareye.com/public/api",
});

const axiosWhatsappInstance = axios.create({
  baseURL: "https://public.doubletick.io",
});

const axiosMedflickInstance = axios.create({
  baseURL: "https://dev.medflick.com/api",
});

export { axiosInstance, axiosWhatsappInstance, axiosMedflickInstance };

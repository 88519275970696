import React, { useState } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const SetReminder = ({ patientId, userId, getReminderList, reminderList }) => {
  const [isReminderEnabled, setIsReminderEnabled] = useState(false);
  const [reminderDate, setReminderDate] = useState("");
  const [reminderDescription, setReminderDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedTime, setSelectedTime] = useState("");
  const [loading, setLoading] = useState(false);
  const times = Array.from({ length: 10 }, (_, i) => {
    const hour24 = i + 10;
    const hour12 = hour24 % 12 === 0 ? 12 : hour24 % 12;
    return `${String(hour12).padStart(2, "0")}:00`;
  });

  const handleReminderToggle = (event) => {
    setIsReminderEnabled(event.target.checked);
    if (!event.target.checked) {
      setErrors({});
    }
  };

  const handleTimeChange = (e) => setSelectedTime(e.target.value);

  const handleDateChange = (event) => {
    setReminderDate(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setReminderDescription(event.target.value);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!reminderDate) {
      newErrors.reminderDate = "Please select a date for the reminder";
    }
    if (!selectedTime) {
      newErrors.selectedTime = "Please select a time for the reminder";
    }
    if (!reminderDescription) {
      newErrors.reminderDescription = "Description is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateFields()) {
      return;
    }

    const reminderData = {
      schedule_date: `${reminderDate} ${selectedTime}:02`,
      description: reminderDescription,
      patient_id: patientId,
      agent_id: userId,
      //   reminder_name: reminderType,
    };

    try {
      setLoading(true);
      const response = await axiosInstance.post("/schedulePost", reminderData);

      alert("Reminder set successfully");
      setReminderDate("");
      setReminderDescription("");
      setSelectedTime("");

      try {
        await getReminderList(patientId, userId);
      } catch (callbackError) {
        console.error("Error fetching reminder list:", callbackError);
      }
    } catch (error) {
      console.error("Error during reminder submission:", error);
      alert("Failed to set reminder");
    } finally {
      setLoading(false);
    }
  };

  const today = new Date();
  today.setDate(today.getDate() + 1);
  const minDate = today.toISOString().split("T")[0];
  return (
    <>
      <div>
        {/* <label>Set Reminder</label> */}
        <div>
          <input
            type="checkbox"
            id="setReminder"
            name="setReminder"
            checked={isReminderEnabled}
            onChange={handleReminderToggle}
          />
          <label htmlFor="setReminder" style={{ fontWeight: "700" }}>
            Enable Reminder
          </label>
        </div>

        {/* Show reminder fields only if reminder is enabled */}
        {isReminderEnabled && (
          <>
            <div id="reminderFields">
              <div>
                <label htmlFor="reminderDate">Reminder Date</label>
                <input
                  type="date"
                  id="reminderDate"
                  name="reminderDate"
                  value={reminderDate}
                  onChange={handleDateChange}
                  min={minDate}
                />
                {errors.reminderDate && (
                  <p className="error">{errors.reminderDate}</p>
                )}
              </div>
              <div>
                <label htmlFor="reminderTime">Reminder Time</label>
                <select
                  id="reminderTime"
                  name="reminderTime"
                  value={selectedTime}
                  onChange={handleTimeChange}
                >
                  <option value="">Select time</option>
                  {times.map((time, index) => (
                    <option key={index} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
                {errors.selectedTime && (
                  <p className="error">{errors.selectedTime}</p>
                )}
              </div>

              <div>
                <label htmlFor="reminderDescription">Description</label>
                <textarea
                  id="reminderDescription"
                  name="reminderDescription"
                  placeholder="Enter reminder description"
                  value={reminderDescription}
                  onChange={handleDescriptionChange}
                ></textarea>
                {errors.reminderDescription && (
                  <p className="error">{errors.reminderDescription}</p>
                )}
              </div>
              <button type="submit" onClick={handleSubmit} disabled={loading}>
                {loading ? "Loading..." : "Submit Reminder"}
              </button>
            </div>
          </>
        )}

        {reminderList?.length > 0 ? (
          <div className="opinion-table-container">
            <table className="opinion-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>schedule_date</th>
                  <th>description</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {reminderList?.map((opinion) => (
                  <tr key={opinion?.id}>
                    <td>{opinion?.id}</td>
                    <td>{opinion?.schedule_date}</td>
                    <td>{opinion?.description}</td>
                    <td>{new Date(opinion?.created_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p style={{ color: "red" }}>No Reminder available.</p>
        )}
      </div>
    </>
  );
};

export default SetReminder;
